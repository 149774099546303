import {urlFilter} from './url_filter.js';
export default function (selector, ym_goal_id, gl_goal_id, gl_goal_category) {



	[].slice.call(document.querySelectorAll(selector)).forEach(function (elem) {
		elem.addEventListener("click", function () {
			if (ym_goal_id) {
				window.ym && ym(window.ya_id, "reachGoal", ym_goal_id, {"URL": urlFilter(document.location.href)});
			}
			if (gl_goal_id) {
				window.gtag &&
					gtag("event", gl_goal_id, {
						event_category: gl_goal_category
					});
			}
		});
	});
}